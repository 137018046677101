import {
  ExperienceType,
  ProjectType,
  SectionsType,
  UserType,
} from "../Types/Types";

export const userProjects: ProjectType[] = [
  {
    name: "Podcai",
    status: "Active",
    brief: `Daily personalized news podcasts - Powered by AI.`,
    description: `Stay curious, Stay informed.
    Daily personalized news podcasts powered by AI.
    A convenient and enjoyable way
    to stay informed about the topics that interest you.`,
    websiteLink: "https://www.podcai.co",
    githubLink: "",
    tech: {
      front: [
        "TypeScript",
        "React",
        "Redux",
        "TanStack Query",
        "React Router",
        "Axios",
      ],
      back: ["Python"],
      style: ["MUI"],
      tests: ["Jest"],
      devOps: [
        "AWS",
        "GitHub",
        "S3",
        "RDS",
        "GitHub Actions",
        "SQS",
        "CloudFront",
      ],
      db: ["MySQL"],
    },
    star: true,
  },
  {
    name: "Personal website",
    status: "Active",
    brief: `My personal website.`,
    description: ``,
    tech: {
      front: ["TypeScript", "React", "Redux"],
      style: ["MUI"],
      devOps: ["Cloudflare"],
    },
    star: true,
  },
  {
    name: "CV-Tracker",
    status: "Building",
    websiteLink: "https://cv-tracker-public.pages.dev/",
    githubLink: "https://github.com/MatanAvr/CV-Tracker-public",
    brief:
      "An easy and convenient way to track your journy finding your next job.",
    description:
      "An easy and convenient way to track your journy finding your next job.",
    tech: {
      front: ["React", "TypeScript"],
      style: ["MUI"],
      devOps: ["Cloudflare"],
    },
  },

  {
    name: "Cryptocurrency Trading Bot",
    status: "Active",
    brief:
      "Built an algo-trading bot that executes trading orders for new crypto-currncies adopted by Binance exchange.",
    description: "",
    tech: {
      back: [
        "Python",
        // "Windows OS installed on tiny and weak AWS ec2 instance running pycharm program with the relevant code",
      ],
      devOps: ["AWS"],
    },
  },

  {
    name: "Sorting-algo",
    status: "On hold",
    brief: "Small project which visualize sorting algorithms.",
    description: "",
    tech: {
      front: ["JavaScript", "React"],
      style: ["MUI"],
      devOps: ["Firebase"],
    },
  },

  {
    name: "Simple trade",
    status: "On hold",
    brief: "Built a web app that allows you to simulate basic stock trading.",
    description:
      "Developed a web-based platform empowering users to engage in simulated stock trading, offering an immersive experience in the dynamics of the financial markets.",
    tech: {
      front: ["React", "HTML", "CSS"],
      back: ["Node.js"],
      style: ["MUI"],
      db: ["MongoDB"],
      devOps: ["Firebase"],
    },
  },

  {
    name: "Tic-Tac-Toe vs AI",
    status: "Completed",
    brief: "Play tic-tac-toe against chat-gpt.",
    description: "",
    tech: {
      front: ["React", "HTML", "CSS", "TypeScript"],
      back: ["Python", "ChatGPT"],
      db: ["MongoDB"],
      devOps: ["Firebase"],
    },
  },

  {
    name: "Tax Calculator Israel",
    status: "Completed",
    brief:
      "Built a web app that calculates net monthly direct and indirect taxes.",
    description: "",
    tech: {
      front: ["React", "HTML", "CSS", "JavaScript"],
      back: ["Python"],
      db: ["MongoDB"],
      devOps: ["Firebase"],
    },
  },
];

export const userExperience: ExperienceType[] = [
  {
    role: "Fullstack Developer",
    companyName: "ByondPitch",
    startDate: "2022",
    endDate: "2023",
    description: `I've spearheaded backend and frontend development, creating new features and APIs while prioritizing performance.
    With expertise in React, Express, and TypeScript, I collaborate closely with UI/UX teams to deliver exceptional user experiences 
    in fast-paced startup environments.`,
    bullets: [
      `Develop backend and frontend new features and functionalities by writing and testing code.`,
      `Design and develop APIs to support new features, ensuring secure and scalable data exchange between system components.`,
      `Troubleshoot and resolve technical issues to ensure high-quality performance.`,
      `Utilize React and Express frameworks to develop robust, responsive web applications.`,
      `Write clean, efficient, and scalable code using TypeScript, ensuring maintainability of the codebase.`,
      `Work collaboratively with a small team in a fast-paced startup environment to deliver high-quality software solutions.`,
      `Collaborating closely with UI/UX teams to ensure the successful implementation of designs and deliver an exceptional user experience.`,
    ],
  },
  {
    role: "R&D Engineer",
    companyName: "Azemba",
    startDate: "2021",
    endDate: "2022",
    description: `I've overseen projects from inception to completion, defining requirements, timelines, and budgets. 
    Additionally, I've developed comprehensive systems integrating mechanics, electronics, and software to meet project goals effectively.`,
    bullets: [
      `Managed projects by defining requirements, timelines, and budgets.`,
      `Developed multidisciplinary systems that includes mechanics, electronics and software.`,
    ],
  },
  {
    role: "R&D Engineer",
    companyName: "EarlySense",
    startDate: "2019",
    endDate: "2021",
    description: `I've enhanced multidisciplinary test equipment and spearheaded an Engineering Change Order (ECO) process
     for modeling and producing a new prototype, overseeing engineering, testing, and validation documentation.`,

    bullets: [
      `Developed and improved multidisciplinary test equipment.`,
      `Led an ECO for modeling and production of a new prototype, which included engineering, testing, and validation documentation.`,
    ],
  },
  {
    role: "R&D Engineer",
    companyName: "Inomec",
    startDate: "2018",
    endDate: "2019",
    description: `I've brought mechanical devices from concept to completion, aligning them closely with customer needs.
    I've thrived in a fast-paced environment, managing multiple projects simultaneously within tight deadlines.`,
    bullets: [
      `Developed mechanical devices from conceptualization to final product, based on customer requirements.`,
      `Worked on multiple projects concurrently in a fast-paced environment with tight schedules.`,
    ],
  },
];

export const defaulUserType: UserType = {
  fullname: "Matan Avraham",
  occupation: "Software Developer",
  profileImageUrl: "",
  email: "matan4900@gmail.com",
  school: {
    name: "Technion",
    startYear: "2014",
    endYear: "2018",
    degree: "B.Sc. Mechanical Engineering",
  },
  location: "Israel",
  phone: "050-910-2790",
  description: `I'm a dedicated software developer who thrives on challenges, learns quickly, and values collaboration. Seeking opportunities to contribute in a dynamic environment.`,
  links: {
    github: "https://github.com/MatanAvr",
    linkdein: "https://www.linkedin.com/in/matan-avraham-il/",
    youtube: "",
    // youtube: "https://www.youtube.com/channel/UCzh5zzNylw495qG6cIay7Ew",
    x: "",
    // x: "https://twitter.com/Matan_Avraham1",
    instagram: "",
  },
  languages: ["Hebrew", "English"],
  projects: userProjects,
  experience: userExperience,
};

export const sectionItems: SectionsType[] = [
  "Experience",
  "Skills",
  "Projects",
];

// UI consts
export const SECTION_PY = 8;

// System
export const LOCAL_STORAGE_THEME_KEY = "theme";
